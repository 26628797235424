import React, { FC } from 'react';

import { IMenuItem } from './interfaces';

import './menu-item.scss';

// menu item
const MenuItem: FC<IMenuItem> = ({ first, second, pathname }) => {
  // render
  return (
    <a className="menu-item" href={second} data-active={second === pathname}>
      <span className="text">{first}</span>
    </a>
  );
};

export default MenuItem;