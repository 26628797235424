import '../../nanoid/polyfill';

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import LinkExternal from '../../Link/LinkExternal';
import { withMediaQueries } from '../../MediaQueries';

import { IWebdoorInfo, IWebdoorItemInfo } from './interfaces';

import './webdoor-info.scss';

// webdoor info
const WebdoorInfo: FC<IWebdoorInfo> = ({ item, queries }) => {
  const { t }: any = useTranslation();

  const {
    attributes: {
      field_subtitle,
      field_link_tipo,
      field_webdoor_video,
      field_link_url,
      title 
    }
  }: IWebdoorItemInfo = item;
  
  // render
  return (
    <div
      key={nanoid()}
      className="webdoor-info">
        <LinkExternal
          href={field_link_url}
          target={field_link_tipo}>
            <h1 className="title-h1">{title}</h1>
            <h1 className="title-h1 ts" >{field_subtitle}</h1>
        </LinkExternal>
      
      {(queries === 'xs' || queries === 'sm') &&
        <LinkExternal
          className="btn-border video"
          href={field_webdoor_video || ''}
          target="_blank"
          rel="noopener noreferrer">{t('see_video')}</LinkExternal>}
    </div>
  );
};

export default withMediaQueries(WebdoorInfo);