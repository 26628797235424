import React, { FC } from 'react';

import { ITitleText } from './interfaces';

import './title-text.scss';

// title
const TitleText: FC<ITitleText> = ({ attributes }) => {
  const { field_title, field_title_tag } = attributes;

  // render
  return (
    <div className="title-text" data-type={field_title_tag}>
      <h1 className="text">{field_title}</h1>
    </div>
  );
};

export default TitleText;