import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Image from '../../../Image';

import { IListAddressItem } from './interfaces';

import './list-address-item.scss';

// list address item
const ListAddressItem: FC<IListAddressItem> = ({ linkText, attributes, relationships }) => {
  const { body: { value }, title, field_link_url } = attributes;
  const { field_image: { data: { id } } } = relationships;

  // render
  return (
    <div className="list-address-item">
      <div className="list-address-item--image">
        <Image id={id} options={{ alt: title }} />
      </div>

      <div className="list-address-item--info">
        <p className="title">{title}</p>
        <div className="description" dangerouslySetInnerHTML={{ __html: value }} />

        {field_link_url.indexOf('//') !== -1 && <a
          className="btn-border"
          href={field_link_url}
          target="_blank"
          rel="noopener noreferrer">
          {linkText}
          <span className="icon icon-arrow-right-min"></span>
        </a>}
        {field_link_url.indexOf('//') === -1 && <Link
          className="btn-border"
          to={field_link_url}
          >
          {linkText}
          <span className="icon icon-arrow-right-min"></span>
        </Link>}
      </div>
    </div>
  );
};
ListAddressItem.defaultProps = {
  linkText: 'Ver no mapa',
}
export default ListAddressItem;